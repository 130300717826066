import { Routes } from '@angular/router';
import { environment } from './app.environment';
import { siofokRoutes, standardRoutes, ticketRoutes } from './app.routes';
import { siofokSsrRoutes, standardSsrRoutes, ticketSsrRoutes } from './app.ssr';

export interface AppFlavor {
  name: string,
  routes: Routes,
  ssrRoutes: string[],
  eventRoutePrefix: string,
  businessRoutePrefix: string,
}

const flavors = new Map<'standard' | 'tickets' | 'siofok', AppFlavor>([
  [
    'standard',
    {
      name: 'standard',
      routes: standardRoutes,
      ssrRoutes: standardSsrRoutes,
      eventRoutePrefix: '/e/',
      businessRoutePrefix: '/b/',
    }
  ],
  [
    'tickets',
    {
      name: 'tickets',
      routes: ticketRoutes,
      ssrRoutes: ticketSsrRoutes,
      eventRoutePrefix: '/',
      businessRoutePrefix: '/',
    }
  ],
  [
    'siofok',
    {
      name: 'siofok',
      routes: siofokRoutes,
      ssrRoutes: siofokSsrRoutes,
      eventRoutePrefix: '/e/',
      businessRoutePrefix: '/b/',
    }
  ],
]);

export function getActiveAppFlavor(): AppFlavor {
  return flavors.get(environment.flavor)!;
}